import React, { useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import { useState } from "react"

const useDeviceLight = () => {
  const [state, setState] = useState(0)
  useEffect(() => {
    if ("AmbientLightSensor" in window) {
      const sensor = new window.AmbientLightSensor()
      sensor.onreading = () => {
        setState(sensor.illuminance)
      }
      sensor.onerror = event => {
        console.log(event.error.name, event.error.message)
      }
      sensor.start()
    }
  })
  return state > 50 ? "light" : "dark"
}
const IndexPage = () => {
  const mode = useDeviceLight()
  const theme = {
    dark: {
      bg: "bg-gray-900",
      text: "text-green-200",
    },
    light: {
      bg: "bg-gray-100",
      text: "text-green-900",
    },
  }
  const { bg, text } = theme[mode]
  return (
    <Layout>
      <SEO title="Home" />
      <section
        className={
          "h-screen flex justify-center items-center duration-300 " + bg
        }
      >
        <div className={"container max-w-sm " + text}>
          <h1
            className="font-display text-center mb-8"
            style={{ fontSize: 120, lineHeight: 0.8 }}
          >
            zephyr valley
          </h1>
          <code className="w-64 text-xs opacity-50 leading-loose">
            > There are two ways of constructing a software design: One way is
            to make it so simple that there are obviously no deficiencies and
            the other way is to make it so complicated that there are no obvious
            deficiencies.
          </code>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
